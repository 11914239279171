<template>
  <b-row>
    <b-col lg="12" sm="12">
        <template>
          <b-overlay :show="loader">
            <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
              <template v-slot:projectNameSlot>
                {{ }}
              </template>
              {{ }}
            </list-report-head>
            <b-row class="">
              <b-col sm="2">
                <b>{{ $t('globalTrans.year') }}</b>
              </b-col>
              <b-col sm="4">
                : {{ $n(item.applicants[0].app_main.year, { useGrouping: false }) }}
              </b-col>
              <b-col sm="2">
                <b>{{ $t('ditfConfig.fair_name') }}</b>
              </b-col>
              <b-col sm="4">
                : {{ currentLocale === 'bn' ? item.applicants[0].app_main.fair_name.title_bn : item.applicants[0].app_main.fair_name.title_en }}
              </b-col>
              <b-col sm="2">
                <b>{{ $t('ditfConfig.fare_floor') }}</b>
              </b-col>
              <b-col sm="4">
                : {{ $n(item.stall_cat_detail.floor_price, { minimumFractionDigits: 2 }) }}
              </b-col>
              <b-col sm="2">
                <b>{{ $t('ditfConfig.category') }}</b>
              </b-col>
              <b-col sm="4">
                : {{ currentLocale === 'bn' ? item.stall_cat_detail.cat_name_bn : item.stall_cat_detail.cat_name_en }}
              </b-col>
              <b-col lg="12" sm="12" class="mt-2">
                <b-table-simple bordered hover small>
                  <thead>
                    <tr>
                      <b-th colspan="12" class="text-left p-2" style="background: #B6D0E2 ">
                        {{ $t('ditfConfig.stall_no') }} : {{ item.stall_no }}
                        <!-- {{ $t('ditfConfig.stall_location') }} : {{ currentLocale === 'bn' ? item.stall_location_bn : item.stall_location_en }} -->
                      </b-th>
                    </tr>
                    <tr class="bg-primary text-center">
                      <th style="width:5%">{{ $t('globalTrans.sl_no') }}</th>
                      <th style="width:8%">{{ $t('ditfAppTradeFairManages.app_id') }}</th>
                      <th style="width:10%">{{ $t('ditfAppTradeFairManages.comp_name') }}</th>
                      <th style="width:10%">{{ $t('ditfAppTradeFairManages.comp_address') }}</th>
                      <th style="width:10%">{{ $t('globalTrans.mobile') }}</th>
                      <th style="width:10%">{{ $t('ditfAppTradeFairManages.quoted_price') }}</th>
                      <th style="width:12%">{{ $t('globalTrans.select') }}</th>
                    </tr>
                  </thead>
                  <b-tbody v-if="stalls.length > 0">
                      <b-tr v-for="(item, index) in stalls" :key="index" class="text-center">
                          <b-td>{{ $n(index + 1) }}</b-td>
                          <b-td>{{ item.app_main.app_auto_id }} </b-td>
                          <b-td>{{ currentLocale === 'bn' ? item.app_main.comp_name_bn : item.app_main.comp_name_en }}</b-td>
                          <b-td> {{ item.app_main.address?.country_type === 1 ? AddressHelper.getCommonAddress(item.app_main.address) : getForeignAddress(item.app_main.address) }}</b-td>
                          <b-td>{{ item.app_main.mobile_no | mobileNumber }}</b-td>
                          <b-td>{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td v-if="stallAssignFlag">
                            <slot v-if="item.status === 2">
                              <span>{{ item.booked_stall_no }}</span>
                              <span class="badge badge-success">
                                {{ $t('ditfAppTradeFairManages.assign') }}
                              </span>
                            </slot>
                          </b-td>
                          <b-td v-else>
                            <span v-if="item.is_reserved === 1" class="badge badge-info"> {{ $t('ditfAppTradeFairManages.already_assign') }} </span>
                            <span v-else>
                              <span v-if="item.app_main.pay_status === 2">

                                <span v-if="StallCatDetail.price_type == 2">
                                  <b-form-checkbox
                                    :id="'checkbox-1'+index"
                                    v-model="item.is_select"
                                    :disabled="is_active ? (item.is_select === 1 ? false : true) : false"
                                    @input="isSelect($event)"
                                    :name="'checkbox-1'+index"
                                    :value=1
                                    :unchecked-value=0
                                  >
                                  </b-form-checkbox>
                                </span>
                                <span v-else>
                                  <b-form-checkbox
                                    :id="'checkbox-1'+index"
                                    v-model="item.is_select"
                                    :disabled="isFixedDisabled(item)"
                                    :name="'checkbox-1'+index"
                                    :value=1
                                    :unchecked-value=0
                                  >
                                  </b-form-checkbox>
                                  <b-form-input
                                    id="circular_title_en"
                                    v-if="item.is_select"
                                    placeholder="Stall No"
                                    v-model="item.booked_stall_no"
                                  ></b-form-input>
                                </span>
                              <!-- <b-button  variant="primary" size="sm" @click="assignStall(item)"><i class="text-white ri-check-line"></i></b-button> -->
                              </span>
                              <span v-else class="badge badge-danger">{{ $t('globalTrans.refunded') }}</span>
                            </span>
                          </b-td>
                      </b-tr>
                  </b-tbody>
                  <b-tbody v-else>
                      <b-tr class="text-right">
                        <b-td :colspan="6" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                      </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row class="text-right">
              <b-col>
                  <b-button :disabled="is_active == 1 ? false : true" type="button" variant="success" @click="finalSave()" class="mr-2 btn-sm">{{ $t('ditfAppTradeFairManages.assign_btn') }}</b-button>
                  <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </template>
    </b-col>
  </b-row>
</template>
<script>

import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import { getAllApplicantApplyStall, storeAssign } from '../../api/routes'
import AddressHelper from '@/utils/area-type-address'
export default {
  name: 'Details',
  props: ['stallId', 'item'],
  components: {
    ListReportHead
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      baseUrl: internationalTradeFairServiceBaseUrl,
      loader: false,
      stalls: [],
      StallCatDetail: '',
      is_active: false,
      stallAssignFlag: false
    }
  },
  created () {
    if (this.stallId) {
      this.getAllApplicantStallList(this.stallId)
      this.StallCatDetail = this.item.stall_cat_detail
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    isSelect (value) {
      if (parseInt(value) === 1) {
        this.is_active = true
      } else {
        this.is_active = false
      }
    },
    isFixedDisabled (item) {
        const selectedStall = this.stalls.filter(item => item.is_select === 1)
        if (selectedStall !== undefined && (selectedStall.length > 0)) {
          this.is_active = true
        } else {
          this.is_active = false
        }

        if (selectedStall !== undefined && (selectedStall.length >= this.StallCatDetail.stall_qty)) {
          return !item.is_select
        } else {
          return false
        }
    },
    getForeignAddress (item) {
      if (this.$i18n.locale === 'bn') {
        return item.state_bn + ', ' + item.city_bn
      } else {
        return item.state_en + ', ' + item.city_en
      }
    },
    finalSave () {
      this.$swal({
        title: this.$t('globalTrans.confirm_assign_msg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.assignStall()
        }
      })
    },
    getAllApplicantStallList (stallId) {
      this.loader = true
      // const params = Object.assign({ stall_id: item.stall_id }, { stall_cat_id: item.stall_cat_id }, { app_main_id: item.app_main_id })
      const params = {
        stall_cat_id: this.item.stall_cat_det_id,
        stall_id: stallId
      }
      RestApi.getData(internationalTradeFairServiceBaseUrl, getAllApplicantApplyStall, params).then(response => {
        if (response.success) {
          const applicants = response.data.applicants
          const appliedIds = response.reserved_appliant_ids
          if (response.data && applicants.length) {
            const stallAssignFlag = applicants.find(el => el.status === 2)
            if (stallAssignFlag) {
              this.stallAssignFlag = true
            }
            this.stalls = applicants.sort((a, b) => b.quoted_price - a.quoted_price).map((item, index) => {
              const existApplicant = appliedIds.find(tmp => tmp === item.app_main_id)
              if (typeof existApplicant !== 'undefined') {
                return Object.assign({}, item, { serial: index }, { is_reserved: 1 })
              }
              return Object.assign({}, item, { serial: index }, { is_reserved: 0, is_active: 0 })
            })
          } else {
            this.stalls = []
          }
          this.loader = false
        } else {
          this.loader = false
        }
      })
    },
    async assignStall () {
      let result = null
      this.loader = true
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })

      const item = this.stalls.find(el => parseInt(el.is_select) === 1)
      if (typeof item === 'undefined') {
        return
      }
      // const params = Object.assign({ stall_id: item.stall_id }, { stall_cat_id: item.stall_cat_id }, { app_main_id: item.app_main_id })
      const stallsArr = []
      const selectedStalls = this.stalls.filter(item => item.is_select === 1)
      selectedStalls.forEach(element => {
        const params = Object.assign(
          { stall_id: element.stall_id },
          { stall_cat_id: element.stall_cat_id },
          { app_main_id: element.app_main_id },
          { booked_stall_no: element.booked_stall_no }
        )
        stallsArr.push(params)
      })

      result = await RestApi.postData(internationalTradeFairServiceBaseUrl, storeAssign, stallsArr)
      this.loader = false
      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: true })
        this.$toast.success({
          title: 'Success',
          message: result.message,
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    async pdfExport (item) {
      this.loader = true
      const customItem = {
        year: item.applicants[0].app_main.year,
        title_bn: item.applicants[0].app_main.fair_name.title_bn,
        title_en: item.applicants[0].app_main.fair_name.title_en,
        floor_price: item.stall_cat_detail.floor_price,
        cat_name_bn: item.stall_cat_detail.cat_name_bn,
        cat_name_en: item.stall_cat_detail.cat_name_en,
        stall_cat_id: item.stall_cat_det_id,
        stall_id: item.id
      }
      const params = Object.assign({}, { local: this.$i18n.locale, org_id: 2, request_type: 'pdf' }, customItem)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, getAllApplicantApplyStall, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.loader = false
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
